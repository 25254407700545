<template>
  <Header :header_data="header_data"/>
  <div class="mnemonic" :class="type != 1 ? 'mnemonic1' : ''">
    <div class="title" v-if="type != 6">{{ (type == 1 || type == 2) ? '认识助记符' : '学习助记符' }}</div>
    <!-- 助记符第一页 -->
    <div v-if="type == 1">
      <!-- 进度条 -->
      <div class="progress">
        <el-progress :text-inside="true" :stroke-width="20" :percentage="totalPercentage"></el-progress>
        <!-- <div class="icons">
          <span class="icon" v-for="(item) in 5" :key="item">
            <img v-if="percentage/20 < item" src="../../../assets/student/zjfcuo.png" alt="">
            <img v-else src="../../../assets/student/zjfdui.png" alt="">
          </span>
        </div> -->
        <!-- 数量 -->
        <div class="quantity"> {{ pages.Learned }}/{{ pages.total }}</div>
      </div>
      <!-- 轮播 -->
      <div class="swipe" v-if="isShow">
        <div class="leftArrow">
          <img src="../../../assets/student/zjfleft.png" @click="arrowClick('left')"/>
        </div>
        <el-carousel @change="swipeChange" :autoplay="false" trigger="click" arrow="never" id="cardShow"
                     ref="dropdownRef">
          <el-carousel-item v-for="item in alllist" :key="item">
            <div class="swipeitem" v-for="ele in item" :key="ele">
              <img :src="ele.tpdz" alt="">
              <div>{{ ele.dc }}</div>
              <span v-if="ele.sfxx">
                <img src="../../../assets/student/zjfdui.png" alt="">
              </span>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="rightArrow">
          <img src="../../../assets/student/zjfright.png" @click="arrowClick('right')"/>
        </div>
      </div>
      <!-- 开始学习 -->
      <div class="gostudy" v-if="showHide >= pages.currentPage && btnstate" @click="gostudy" id="gostudy">
        <img src="../../../assets/student/zjfbtnblue.png" alt="">
        <!-- <img v-if="btnstate" src="../../../assets/student/zjfbtnblue.png" alt="">
        <img v-else src="../../../assets/student/zjfbtnhui.png" alt=""> -->
        <span>开始学习</span>
      </div>
    </div>
    <!-- 第二步 -->
    <div v-if="type == 2">
      <!-- 进度条 -->
      <div class="progress">
        <el-progress :text-inside="true" :stroke-width="20" :percentage="totalPercentage +1"></el-progress>
        <!-- 数量 -->
        <div class="quantity"> {{ pages.Learned + 1 }}/{{ pages.total }}</div>
      </div>
      <!-- 图片 -->
      <div class="image1">
        <img src="../../../assets/student/topleft.png" alt="">
        <img src="../../../assets/student/topright.png" alt="">
        <img src="../../../assets/student/bottomleft.png" alt="">
        <img src="../../../assets/student/bottomright.png" alt="">
        <img :src="createObj && createObj.tpdz" alt="">
      </div>
      <div class="type2Btn">
        <div class="btnbsx" @click="familiar(1,currentArr[3])">
          <img src="../../../assets/student/zjfbsx.png" alt="">
          <span>不 熟 悉</span>
        </div>
        <div class="btnsx" @click="familiar(2,currentArr[3])">
          <img src="../../../assets/student/zjfsx.png" alt="">
          <span>熟 悉</span>
        </div>
      </div>
    </div>
    <!-- 点击不熟悉学习 -->
    <div v-if="type == 3">
      <!-- 进度条 -->
      <div class="progress">
        <el-progress :text-inside="true" :stroke-width="20" :percentage="totalPercentage+1"></el-progress>
        <!-- 数量 -->
        <div class="quantity"> {{ pages.Learned + 1 }}/{{ pages.total }}</div>
      </div>
      <!-- 图片 -->
      <div class="image1">
        <img src="../../../assets/student/topleft.png" alt="">
        <img src="../../../assets/student/topright.png" alt="">
        <img src="../../../assets/student/bottomleft.png" alt="">
        <img src="../../../assets/student/bottomright.png" alt="">
        <img :src="createObj && createObj.tpdz" alt="">
      </div>
      <div class="studyword">
        <p>{{ createObj && createObj.dc }}</p>
        <p>{{ createObj && createObj.cy }}</p>
      </div>
      <div class="type3btn" @click="familiar(3,currentArr[3])">
        <img src="../../../assets/student/zjfbtnblue.png" alt="">
        <span>已熟悉</span>
      </div>
    </div>
    <!-- 根据单词选助记符 -->
    <div v-if="type == 4">
      <div class="type4title">请 根 据 单 词 选 择 正 确 的 助 记 符</div>
      <div class="image2">
        <img src="../../../assets/student/topleft.png" alt="">
        <img src="../../../assets/student/topright.png" alt="">
        <img src="../../../assets/student/bottomleft.png" alt="">
        <img src="../../../assets/student/bottomright.png" alt="">
        <span>{{ createObj && createObj.dc }}</span>
      </div>
      <!-- 选项 -->
      <div class="option">
        <div @click="mapSelection(currentArr[0])">
          <span>A.</span>
          <img :src="currentArr[0] && currentArr[0].tpdz" alt="">
        </div>
        <div @click="mapSelection(currentArr[1])">
          <span>B.</span>
          <img :src="currentArr[1] && currentArr[1].tpdz" alt="">
        </div>
        <div @click="mapSelection(currentArr[2])">
          <span>C.</span>
          <img :src="currentArr[2] && currentArr[2].tpdz" alt="">
        </div>
        <div @click="mapSelection(currentArr[3])">
          <span>D.</span>
          <img :src="currentArr[3] && currentArr[3].tpdz" alt="">
        </div>
      </div>
    </div>
    <!-- 根据助记符选择正确的单词 -->
    <div v-if="type == 5">
      <div class="type4title">请 根 据 助 记 符 选 择 正 确 的 单 词</div>
      <div class="image1 image2 image3">
        <img src="../../../assets/student/topleft.png" alt="">
        <img src="../../../assets/student/topright.png" alt="">
        <img src="../../../assets/student/bottomleft.png" alt="">
        <img src="../../../assets/student/bottomright.png" alt="">
        <img :src="createObj && createObj.tpdz" alt="">
      </div>
      <!-- 选项 -->
      <div class="option optionxdc">
        <div @click="selectword(currentArr[0])">
          <span>A.</span>
          <span
              :style="(currentArr[0] && currentArr[0].dc.replaceAll('，', ',')).length > 12 ? 'font-size:2vw':''">{{
              currentArr[0] && currentArr[0].dc.replaceAll('，', ',')
            }}</span>
        </div>
        <div @click="selectword(currentArr[1])">
          <span>B.</span>
          <span
              :style="(currentArr[1] && currentArr[1].dc.replaceAll('，', ',')).length > 12 ? 'font-size:2vw':''">{{
              currentArr[1] && currentArr[1].dc.replaceAll('，', ',')
            }}</span>
        </div>
        <div @click="selectword(currentArr[2])">
          <span>C.</span>
          <span
              :style="(currentArr[2] && currentArr[2].dc.replaceAll('，', ',')).length > 12 ? 'font-size:2vw':''">{{
              currentArr[2] && currentArr[2].dc.replaceAll('，', ',')
            }}</span>
        </div>
        <div @click="selectword(currentArr[3])">
          <span>D.</span>
          <span
              :style="(currentArr[3] && currentArr[3].dc.replaceAll('，', ',')).length > 12 ? 'font-size:2vw':''">{{
              currentArr[3] && currentArr[3].dc.replaceAll('，', ',')
            }}</span>
        </div>
      </div>
    </div>
    <!-- 学习下一组/返回 -->
    <div class="test" v-if="type == 6">
      <div class="congratulations">
        <div class="image">
          <img src="../../../assets/student/testcomplete.png" alt="">
          <span class="text">
            太棒了，本组助记符学习完成 !
            <div></div>
            继续加油哦 !
          </span>
        </div>
      </div>
      <div class="type2Btn returnbtn">
        <div class="btnbsx" @click="familiar(4)">
          <img src="../../../assets/student/zjfbsx.png" alt="">
          <span>返 回</span>
        </div>
        <div class="btnsx" @click="familiar(5)">
          <img src="../../../assets/student/zjfsx.png" alt="">
          <span>学习下一组</span>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</template>
<script>
import Header from "../components/zjfheader.vue";
import Footer from "../components/footer.vue";
import {getmnemonic, savemnemonic} from "@/api/student.js";
import {ElMessage} from "element-plus";
import {nextTick, onMounted, reactive, ref, computed} from "vue-demi";
import {toRefs} from "vue-demi";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {watch} from "@vue/runtime-core";

export default {
  components: {
    Header,
    Footer,
  },
  setup() {
    const data = reactive({
      overallpercentage: 1, // 总进度条
      btnstate: true, // 开始学习按钮是否置灰
      type: 1, // 助记符状态，1，2，3，4
      swiperList: [],
      pages: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
        Learned: 0, // 助记符已学数量
      },
      // jxInfo: null,
      header_data: [{title: "认识助记符", path: ""}],
      npstudyList: [], // 点击开始学习，获取当前十个助记符里没有学过的
      randomArr: [], // 随机存储三个数组元素，作为错误项展示页面
      currentArr: [], // 当前学习的助记符
      createObj: {},
      zjfpercentage: null, // 学习时进度条
      alllist: [], // 整个轮播需要的数据
      isShow: true, // 轮播dom重新加载
      identification: false, // 判断点击已熟悉返回哪一步
      stopClick: false, // 防止高频点击
    });
    onMounted(() => {
      data.pages.currentPage = sessionStorage.getItem("swipeCurrentPage") || 1;
      // 获取集训信息
      // data.jxInfo = JSON.parse(sessionStorage.getItem("jxInfo"));
      getmnemonicFun();
      // 刷新页面跳转到学习的第一步
      data.type = route.query.type ? route.query.type : 1;
      nextTick(() => {
        // 学习下一个助记符
        if (sessionStorage.getItem("zjfobj")) {
          randomlist();
          // 页面刷新，重新计算进度条，如果session里有十条数据，进度条为createnum*10
          // 如果不到十条数据，10 - 数组长度 + createnum 之后 * 10
          if (
              JSON.parse(sessionStorage.getItem("zjfobj")).zjflist.length == 10
          ) {
            data.zjfpercentage =
                (JSON.parse(sessionStorage.getItem("zjfobj")).createnum + 1) * 10;
          } else {
            data.zjfpercentage =
                (10 -
                    JSON.parse(sessionStorage.getItem("zjfobj")).zjflist.length +
                    JSON.parse(sessionStorage.getItem("zjfobj")).createnum +
                    1) *
                10;
          }
        }
      });
      // 获取整个轮播需要的数据
      getmnemonic({
        // bjid: data.jxInfo.bjid,
        // jxid: data.jxInfo.jxid,
        xsid: store.state.userInfo.yhid,
        orderBy: '',
        currentPage: 1,
        pageSize: 999,
      }).then((res) => {
        data.isShow = false;
        if (res.success && res.result.pageList.list) {
          let arr = [];
          res.result.pageList.list.forEach((ele, index) => {
            if (!arr[parseInt(index / 10)]) {
              arr[parseInt(index / 10)] = [];
            }
            arr[parseInt(index / 10)].push(ele);
          });
          data.alllist = arr;
        } else {
          ElMessage({
            message: res.resultDesc,
            type: "warning",
          });
        }
        if (data.type == 1) {
          nextTick(() => {
            data.isShow = true;
            let currentindex = getCurrentPage();
            setTimeout(() => {
              if (dropdownRef.value) {
                dropdownRef.value.setActiveItem(currentindex);
              }
            }, 50);
          });
        }
      });
    });
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    // 获取助记符列表
    function getmnemonicFun() {
      getmnemonic({
        // bjid: data.jxInfo.bjid,
        // jxid: data.jxInfo.jxid,
        xsid: store.state.userInfo.yhid,
        currentPage: data.pages.currentPage,
        pageSize: data.pages.pageSize,
        orderBy: '',
      }).then((res) => {
        if (res.success) {
          data.swiperList = res.result.pageList.list;
          data.pages.total = res.result.pageList.total;
          data.pages.Learned = res.result.yxsl;
          // 获取请求到的助记符里没有学习过的
          data.npstudyList = data.swiperList.filter((item) => {
            return !item.sfxx;
          });
          // 如果当前页面所有助记符都已学习，开始学习按钮置灰
          data.btnstate = !data.npstudyList.length ? false : true;
        } else {
          ElMessage({
            message: res.resultDesc,
            type: "warning",
          });
        }
      });
    }

    // 走马灯change事件
    const swipeChange = (val) => {
      data.pages.currentPage = val + 1;
      getmnemonicFun();
    };
    // 获取dom
    const dropdownRef = ref(null);
    // 自定义轮播图切换
    const arrowClick = (val) => {
      if (val === "right") {
        if (data.pages.currentPage >= data.pages.total / 10) {
          data.pages.currentPage = 1;
        } else {
          ++data.pages.currentPage;
        }
        getmnemonicFun();
        dropdownRef.value.next();
      } else {
        if (data.pages.currentPage <= 1) {
          data.pages.currentPage = Math.ceil(data.pages.total / 10);
        } else {
          --data.pages.currentPage;
        }
        getmnemonicFun();
        dropdownRef.value.prev();
      }
    };
    // 计算属性计算出总进度条百分比
    const totalPercentage = computed(() => {
      // 已学助记符数量除以总助记符数量
      return Math.floor(
          data.pages.Learned ? (data.pages.Learned / data.pages.total) * 100 : 1
      );
    });
    // 按钮是否显示
    const showHide = computed(() => {
      // 已学数量除以当前页数得出第几页
      if (!data.pages.Learned) {
        return 1;
      } else {
        // 结果是整数加一返回
        if (Number.isInteger(data.pages.Learned / data.pages.pageSize)) {
          return data.pages.Learned / data.pages.pageSize + 1;
        } else {
          // 结果是浮点数，向上取整返回
          return Math.ceil(data.pages.Learned / data.pages.pageSize);
        }
      }
    });
    // 开始学习按钮
    const gostudy = () => {
      if (!data.btnstate && data.swiperList.length) {
        ElMessage({
          message: "助记符不可重复学习哦",
          type: "warning",
        });
        return;
      } else if (!data.btnstate && !data.swiperList.length) {
        ElMessage({
          message: "您已经学习完成所有助记符，请返回首页",
          type: "warning",
        });
      }
      sessionStorage.setItem("swipeCurrentPage", data.pages.currentPage);
      // 小于四说明当前页面助记符不足四个，错误选项在其他页面获取
      if (data.swiperList.length < 4) {
        if (data.pages.currentPage !== 1) {
          getmnemonic({
            // bjid: data.jxInfo.bjid,
            // jxid: data.jxInfo.jxid,
            xsid: store.state.userInfo.yhid,
            currentPage: data.pages.currentPage - 1,
            pageSize: data.pages.pageSize,
            orderBy: '',
          }).then((res) => {
            if (res.success) {
              data.swiperList = res.result.pageList.list;
              //
              console.log(data.npstudyList);
              data.zjfpercentage =
                  (data.swiperList.length - data.npstudyList.length + 1) * 10;
              // 当前页面未学习的助记符存到缓存
              sessionStorage.setItem(
                  "zjfobj",
                  JSON.stringify({zjflist: data.npstudyList, createnum: 0})
              );
              sessionStorage.setItem("zjfarr", JSON.stringify(data.swiperList));
              router.replace({name: "mnemonic", query: {type: 2}});
              randomlist();
              data.type = 2;
            } else {
              ElMessage({
                message: res.resultDesc,
                type: "warning",
              });
            }
          });
        } else {
          ElMessage({
            message: "当前助记符数量过少，无法学习",
            type: "warning",
          });
          return;
        }
      } else {
        data.zjfpercentage = (10 - data.npstudyList.length + 1) * 10;
        // 当前页面未学习的助记符存到缓存
        sessionStorage.setItem(
            "zjfobj",
            JSON.stringify({zjflist: data.npstudyList, createnum: 0})
        );
        sessionStorage.setItem("zjfarr", JSON.stringify(data.swiperList));
        router.replace({name: "mnemonic", query: {type: 2}});
        randomlist();
        data.type = 2;
      }
    };
    // 不熟悉/熟悉
    const familiar = (val, row) => {
      console.log(val, 111)
      // 不熟悉
      if (val == 1) {
        data.type = 3;
      } else if (val == 2) {
        // 熟悉
        data.type = 4;
      } else if (val == 3) {
        // 已熟悉
        data.identification ? data.type = 5 : data.type = 4
        // data.type = 4;
        // 返回
      } else if (val == 4) {
        getmnemonic({
          // bjid: data.jxInfo.bjid,
          // jxid: data.jxInfo.jxid,
          xsid: store.state.userInfo.yhid,
          currentPage: 1,
          pageSize: 999,
          orderBy: '',
        }).then((res) => {
          data.isShow = false;
          if (res.success && res.result.pageList.list) {
            let arr = [];
            res.result.pageList.list.forEach((ele, index) => {
              if (!arr[parseInt(index / 10)]) {
                arr[parseInt(index / 10)] = [];
              }
              arr[parseInt(index / 10)].push(ele);
            });
            data.alllist = arr;
          } else {
            ElMessage({
              message: res.resultDesc,
              type: "warning",
            });
          }
          data.type = 1;
          router.replace({name: "mnemonic"});
          nextTick(() => {
            data.isShow = true;
            let currentindex = getCurrentPage();
            setTimeout(() => {
              if (dropdownRef.value) {
                dropdownRef.value.setActiveItem(currentindex);
              }
            }, 50);
          });
        });
        // data.type = 1;
        // router.replace({ name: "mnemonic" });
        // 学习下一组
      } else if (val == 5) {
        gostudy();
      }
    };
    // 根据单词选助记符
    const mapSelection = (val) => {
      if (val.id == data.createObj.id) {
        data.type = 5;
        randomlist();
      } else {
        data.identification = false
        data.type = 3;
      }
    };
    // 计算得出当前页
    const getCurrentPage = () => {
      var currentindex = null;
      if (!data.pages.Learned) {
        currentindex = 0;
      } else {
        // 结果是整数加一返回
        if (Number.isInteger(data.pages.Learned / data.pages.pageSize)) {
          currentindex = data.pages.Learned / data.pages.pageSize;
        } else {
          // 结果是浮点数，向上取整返回
          currentindex = Math.floor(data.pages.Learned / data.pages.pageSize);
        }
      }
      return currentindex;
    };

    // 根据助记符选单词，最后一步
    async function selectword(val) {
      // 助记符缓存
      let CACHEZJF = JSON.parse(sessionStorage.getItem("zjfobj"));
      if (data.stopClick) {
        return
      }
      data.stopClick = true
      if (val.dc == data.createObj.dc) {
        try {
          const res = await savemnemonic({
            // bjid: data.jxInfo.bjid,
            // bjmc: data.jxInfo.bjmc,
            cjr: store.state.userInfo.yhid,
            id: null,
            // jxid: data.jxInfo.jxid,
            // jxmc: data.jxInfo.jxmc,
            xsid: store.state.userInfo.yhid,
            xsmc: JSON.parse(sessionStorage.getItem("userInfo")).yhm,
            zjfid: data.createObj.id,
          });
          if (res.success) {
            data.identification = false
            getmnemonic({
              // bjid: data.jxInfo.bjid,
              // jxid: data.jxInfo.jxid,
              xsid: store.state.userInfo.yhid,
              currentPage: data.pages.currentPage,
              pageSize: data.pages.pageSize,
              orderBy: '',
            }).then((res) => {
              if (res.success) {
                data.pages.Learned = res.result.yxsl;
              } else {
                ElMessage({
                  message: res.resultDesc,
                  type: "warning",
                });
              }
              setTimeout(() => {
                data.stopClick = false
              }, 500)
            });
            if (CACHEZJF.createnum + 1 >= CACHEZJF.zjflist.length) {
              // 当前页码大于等于total，说明所有助记符已学完，返回首页
              if (data.pages.currentPage >= Math.ceil(data.pages.total) / 10) {
                ElMessage({
                  message: '所有助记符已学完，即将返回首页',
                  type: "success",
                });
                setTimeout(() => {
                  router.replace({name: "home"});
                }, 500);
              } else {
                data.pages.currentPage++;
                sessionStorage.setItem(
                    "swipeCurrentPage",
                    data.pages.currentPage
                );
                getmnemonicFun();
                setTimeout(() => {
                  data.type = 6;
                  router.replace({name: "mnemonic", query: {type: 6}});
                }, 500);
              }
              return;
            }
            data.zjfpercentage += 10;
            sessionStorage.setItem(
                "zjfobj",
                JSON.stringify({
                  zjflist: CACHEZJF.zjflist,
                  createnum: ++CACHEZJF.createnum,
                })
            );
            // 如果createnum >= zjflist.length, 说明当前页面十个所有助记符已学完
          } else {
            ElMessage({
              message: res.resultDesc,
              type: "warning",
            });
            data.stopClick = false
          }
        } catch (err) {
          data.stopClick = false
          console.error(err);
        }
        data.type = 2;
      } else {
        data.identification = true
        data.type = 3;
        data.stopClick = false
      }
      nextTick(() => {
        randomlist();
      });
    }

    // 随机截取三个图片和三个单词，并且和正确答案拼在一起，生成四个元素的数组
    const randomlist = () => {
      // zjfobj
      let CACHEZJFOBJ = JSON.parse(sessionStorage.getItem("zjfobj"));
      // zjfarr
      let CACHZJFARR = [];
      let arr = JSON.parse(sessionStorage.getItem("zjfarr"));
      let dc = []
      arr.forEach(e => {
        if (dc.indexOf(e.dc) === -1) {
          dc.push(e.dc)
          CACHZJFARR.push(e)
        }
      })
      data.createObj = CACHEZJFOBJ.zjflist[CACHEZJFOBJ.createnum];
      data.randomArr = [];
      for (let i = 0; i < CACHZJFARR.length; i++) {
        var num = Math.floor(Math.random() * CACHZJFARR.length);
        // 如果错误选项和正确选项相同，删掉这一项
        if (CACHZJFARR[num].dc == data.createObj.dc) {
          CACHZJFARR.splice(num, 1);
        }
        // 如果
        if (!CACHZJFARR[num]) {
          CACHZJFARR.splice(num, 1);
        } else {
          data.randomArr.push(CACHZJFARR[num]);
          // push进数组的删掉
          CACHZJFARR.splice(num, 1);
        }
        if (data.randomArr.length >= 3) {
          break;
        }
      }
      // 数组乱序
      data.currentArr = [...data.randomArr, ...[data.createObj]].sort(
          function () {
            return 0.5 - Math.random();
          }
      );
      // 修改单词间的逗号,中文逗号修改为英文逗号
      data.currentArr.forEach((item) => {
        if (item.dc.indexOf("，")) {
          item.dc.replaceAll("，", ",");
        }
      });
    };
    watch(() => data.type, (newV, oldV) => {
          if (newV == 1) {
            sessionStorage.removeItem("swipeCurrentPage");
          }
        }
    );
    return {
      ...toRefs(data),
      arrowClick,
      gostudy,
      dropdownRef,
      familiar,
      mapSelection,
      selectword,
      totalPercentage,
      showHide,
      swipeChange,
      getCurrentPage,
    };
  },
};
</script>
<style lang="scss" scoped>
@media (max-height: 700px) {
  #gostudy {
    bottom: 2vw;
  }
}

.mnemonic {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 5.67vw;
  background: url("../../../assets/student/mnemonic.png") no-repeat;
  background-size: 100% 100%;

  .title {
    width: 29.8vw;
    height: 5.78vw;
    margin: 0 auto;
    background: url("../../../assets/student/zjftitle.png") no-repeat;
    background-size: 100% 100%;
    font-size: 2.3vw;
    color: #fff;
    line-height: 5.78vw;
    text-align: center;
  }

  .progress {
    width: 43.8vw;
    height: 1.56vw;
    margin: 16px auto 10px;
    position: relative;

    .quantity {
      width: 6.8vw;
      position: absolute;
      right: -8vw;
      top: -0.7vw;
      font-size: 1.6vw;
      color: #fff;
      font-weight: 600;
    }
  }

  .swipe {
    margin: auto;
    position: relative;
    box-sizing: border-box;
    width: calc(75% - 1.9vw);

    .rightArrow {
      position: absolute;
      right: -5vw;
      top: 10vw;
      width: 3.85vw;
      height: 6.09vw;
      cursor: pointer;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .leftArrow {
      position: absolute;
      left: -5vw;
      top: 10vw;
      width: 3.85vw;
      height: 6.09vw;
      cursor: pointer;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .swipeitem {
    float: left;
    margin-right: 2.2vw;
    margin-bottom: 1vw;
    width: 12.7vw;
    height: 12.2vw;
    background: url("../../../assets/student/zjfback.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

    img {
      display: block;
      width: 60%;
      height: 60%;
      margin: 1vw auto 0;
    }

    div {
      display: block;
      position: absolute;
      bottom: 5%;
      left: 50%;
      font-size: 2vw;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'Times New Roman', Times, serif;
    }

    span {
      width: 2vw;
      height: 2vw;
      display: block;
      position: absolute;
      right: 1vw;
      top: 0;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .swipeitem:nth-child(5) {
    margin-right: 0;
  }

  .swipeitem:nth-child(10) {
    margin-right: 0;
  }
}

// 更换背景
.mnemonic1 {
  background: url("../../../assets/student/zjfstudyBanner.png") no-repeat;
  background-size: 100% 100%;
}

.gostudy {
  position: absolute;
  bottom: 30px;
  right: 12.5vw;
  width: 15vw;
  height: 4.32vw;
  z-index: 2;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.4vw;
  }
}

.image1 {
  width: 24.1vw;
  height: 14.53vw;
  background: url("../../../assets/student/zjf1.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img:nth-child(1) {
    position: absolute;
    top: -0.5vw;
    left: -0.5vw;
  }

  img:nth-child(2) {
    position: absolute;
    top: -0.5vw;
    right: -0.5vw;
  }

  img:nth-child(3) {
    position: absolute;
    bottom: -0.5vw;
    left: -0.5vw;
  }

  img:nth-child(4) {
    position: absolute;
    bottom: -0.5vw;
    right: -0.5vw;
  }

  img:nth-child(5) {
    display: block;
    width: 50%;
    height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.image2 {
  // width: 16.71vw;
  padding: 0 2vw;
  height: 13.6vh;
  background: url("../../../assets/student/zjf2.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 13.6vh;
  font-size: 3vw;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  img:nth-child(1) {
    position: absolute;
    top: -0.5vw;
    left: -0.5vw;
  }

  img:nth-child(2) {
    position: absolute;
    top: -0.5vw;
    right: -0.5vw;
  }

  img:nth-child(3) {
    position: absolute;
    bottom: -0.5vw;
    left: -0.5vw;
  }

  img:nth-child(4) {
    position: absolute;
    bottom: -0.5vw;
    right: -0.5vw;
  }

  span {
    display: block;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.image3 {
  width: 16.71vw;
  height: 18.6vh;
}

.type2Btn {
  position: absolute;
  left: 50%;
  bottom: 15%;
  transform: translateX(-50%);

  div {
    float: left;
    text-align: center;
    position: relative;

    span {
      font-size: 2.5vw;
      color: #fff;
      position: absolute;
      top: 45%;
      left: 50%;
      font-weight: 600;
      transform: translate(-50%, -50%);
    }
  }

  img {
    width: 18.12vw;
    height: 4.68vw;
  }

  div:nth-child(1) {
    margin-right: 6.35vw;
  }
}

.returnbtn {
  img {
    width: 14vw;
    height: 3.6vw;
  }

  span {
    font-size: 1.4vw !important;
  }
}

.studyword {
  position: absolute;
  left: 50%;
  bottom: 18%;
  transform: translateX(-50%);
  font-size: 2.2vw;
  font-weight: bold;
  text-align: center;

  p:nth-child(1) {
    margin-bottom: 1vw;
  }
}

.type3btn {
  position: absolute;
  bottom: 30px;
  right: 12.5vw;
  width: 16vw;
  height: 4.61vw;
  z-index: 2;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.4vw;
  }
}

.type4title {
  margin-top: 1vw;
  text-align: center;
  font-size: 2.2vw;
}

.option {
  width: 80%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10.1vw;
  // display: none;
  div {
    display: flex;
    flex-direction: columns;

    img {
      display: block;
      width: 10.1vw;
      height: 10.1vw;
    }

    span {
      line-height: 10.1vw;
      font-size: 3vw;
      margin-right: 1vw;
    }
  }
}

// 选单词
.optionxdc {
  span:nth-child(1) {
    line-height: 14.1vw;
    font-size: 3vw;
    margin-right: 1vw;
  }

  span:nth-child(2) {
    line-height: 14.1vw;
    font-size: 2.5vw;
    margin-right: 1vw;
  }
}

.congratulations {
  width: 34vw;
  height: 25vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);

  .image {
    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .text {
      position: absolute;
      bottom: 20%;
      width: 94%;
      text-align: center;
      color: #fff;
      font-size: 1.2rem;
    }
  }
}

.practice {
  width: 13rem;
  height: 4rem;
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .text {
    position: absolute;
    top: 0;
    text-align: center;
    color: #fff;
    height: 100%;
    width: 100%;
    font-size: 28px;
    line-height: 4rem;
  }
}
</style>
<style lang="scss">
.mnemonic {
  ul.el-carousel__indicators {
    width: 100%;
  }

  .el-progress-bar__outer {
    background: #fff !important;
    height: 16px !important;
  }

  .el-progress-bar__inner {
    background: #0972e7;
  }

  .el-carousel--horizontal {
    height: 28.84vw;
  }

  .el-carousel__container {
    height: 100%;
  }

  .el-carousel__indicators {
    bottom: 0px;
    // display: none;
  }

  .el-carousel__indicator {
    .el-carousel__button {
      padding: 0.4vw 1.2vw;
      border-radius: 30px;
      background: #fff;
      opacity: 1;
    }
  }

  .el-carousel__indicator.is-active {
    .el-carousel__button {
      background: #5990ff;
    }
  }

  .el-progress-bar__innerText {
    display: none;
  }

  // 切换按钮-左
  .el-carousel__arrow--left {
    left: 50px;
    // background-image: url("../../../assets/images/student/tabLeftIcon.png");
  }

  // 切换按钮-右
  .el-carousel__arrow--right {
    right: 50px;
    // background-image: url("../../../assets/images/student/tabRightIcon.png");
  }
}
</style>